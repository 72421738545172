import { projectStorage } from '@/firebase/config';
import getUser from '@/composables/getUser';

import { ref } from 'vue';

const { user } = getUser();

const delay = (t) => {
    return new Promise((resolve) => {
        setTimeout(resolve.bind(null, t), t);
    });
};

const keepTrying = async (triesRemaining, storageRef) => {
    if (triesRemaining < 0) {
        return Promise.reject('Image conversion is out of tries: could not be converted');
    }

    return storageRef.getDownloadURL().then((url) => {
        return url;
    }).catch((error) => {
        switch (error.code) {
            case 'storage/object-not-found':
                console.log(`Decrement Tries Remaining: ${triesRemaining}`);
                return delay(2000).then(() => {
                    return keepTrying(triesRemaining - 1, storageRef);
                });
            default:
                console.log(error);
                return Promise.reject(error);
        }
    });
};

const useStorage = () => {
    const error = ref(null);
    const url = ref(null);
    const filePath = ref(null);
    const urlMedium = ref(null);
    const filePathMedium = ref(null);
    const urlThumb = ref(null);
    const filePathThumb = ref(null);
    const isImagePending = ref(false);

    const uploadImageResize = async (path, fileName, file, uid) => {
        isImagePending.value = true;
        error.value = null;
        if (!uid) {
            uid = user.value.uid;
        }
        filePath.value = `${path}/${uid}/${fileName}`;
        const storageRef = projectStorage.ref(filePath.value);
        const retryAttempts = 100;

        try {
            await storageRef.put(file);
            // Get the resized thumbnail image
            console.log("Storage Update: Get 200x200");
            filePathThumb.value = filePath.value + '_200x200';
            let storageRefResize = projectStorage.ref().child(filePathThumb.value);
            urlThumb.value = await keepTrying(retryAttempts, storageRefResize);
            // Get the resized medium image
            console.log("Storage Update: Get 600x600");
            filePathMedium.value = filePath.value + '_600x600';
            storageRefResize = projectStorage.ref().child(filePathMedium.value);
            urlMedium.value = await keepTrying(retryAttempts, storageRefResize);
            // Get the resized large image
            console.log("Storage Update: Get 1200x1200");
            filePath.value = filePath.value + '_1200x1200';
            storageRefResize = projectStorage.ref().child(filePath.value);
            url.value = await keepTrying(retryAttempts, storageRefResize);
            console.log("Storage Update: Use Storage Complete");
        } catch (err) {
            console.log(err.message);
            error.value = "Could not upload the image: " + err.message;
        }
        isImagePending.value = false;
    };

    const deleteImage = async (path) => {
        isImagePending.value = true;
        error.value = null;
        const storageRef = projectStorage.ref(path);

        try {
            await storageRef.delete();
        } catch (err) {
            console.log(err.message);
            error.value = "Could not delete the image: " + err.message;
        }
        isImagePending.value = false;
    };

    const getSubscriptionDocumentURL = async (investorId, investmentId) => {
        let filePath = `subscriptionAgreements/${investorId}/${investmentId}.pdf`;
        let docRef = projectStorage.ref(filePath);
        try {
            let res = await docRef.getDownloadURL();
            error.value = "";
            return res;
        } catch (err) {
            error.value = `Subscription document is not available at this time`;
            return;
        }
    };

    const uploadPDF = async (path, fileName, file, uid) => {
        isImagePending.value = true;
        error.value = null;
        if (!uid) {
            uid = user.value.uid;
        }
        filePath.value = `${path}/${uid}/${fileName}`;
        const storageRef = projectStorage.ref(filePath.value);

        try {
            const res = await storageRef.put(file);
            url.value = await res.ref.getDownloadURL();
        } catch (err) {
            console.log(err.message);
            error.value = "Could not upload the image: " + err.message;
        }
        isImagePending.value = false;
    };

    const uploadImage = async (path, fileName, file, uid) => {
        isImagePending.value = true;
        error.value = null;
        if (!uid) {
            uid = user.value.uid;
        }
        filePath.value = `${path}/${uid}/${fileName}`;
        const storageRef = projectStorage.ref(filePath.value);

        try {
            const res = await storageRef.put(file);
            url.value = await res.ref.getDownloadURL();
        } catch (err) {
            console.log(err.message);
            error.value = "Could not upload the image: " + err.message;
        }
        isImagePending.value = false;
    };

    return { isImagePending, url, filePath, urlThumb, urlMedium, filePathMedium, filePathThumb, error, uploadImageResize, uploadPDF, deleteImage, uploadImage, getSubscriptionDocumentURL };
};

export default useStorage;